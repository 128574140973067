import { CButton, CCol, CInput, CLabel, CRow, CSelect } from "@coreui/react";
import "./styles.css";
import { Form } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import { jdContext } from "src/UserContext";
import axios from "axios";
import validate from "src/Vaidation/validation";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import Loading from "../candidates/componants/Loading";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/src/stylesheets/datepicker.scss";
import "./react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
const stateArray = [
  {
    state: "Jammu and Kashmir",
    gstcode: "01",
    city: [
      "Anantnag",
      "Baramula",
      "Jammu",
      "Kathua",
      "Punch",
      "Rajauri",
      "Sopore",
      "Srinagar",
      "Udhampur",
    ],
  },
  {
    state: "Himachal Pradesh",
    gstcode: "02",
    city: ["Mandi", "Nahan", "Palampur", "Shimla", "Solan", "Sundarnagar"],
  },
  {
    state: "Punjab",
    gstcode: "03",
    city: [
      "Amritsar",
      "Barnala",
      "Batala",
      "Bathinda",
      "Dhuri",
      "Faridkot",
      "Fazilka",
      "Firozpur",
      "FirozpurCantt",
      "Gobindgarh",
      "Gurdaspur",
      "Hoshiarpur",
      "Jagraon",
      "JalandharCantt",
      "Jalandhar",
      "Kapurthala",
      "Khanna",
      "Kharar",
      "KotKapura",
      "Longowal",
      "Ludhiana",
      "Malerkotla",
      "Malout",
      "Mansa",
      "Moga",
      "Mohali",
      "Morinda,India",
      "Mukerian",
      "Muktsar",
      "Nabha",
      "Nakodar",
      "Nangal",
      "Nawanshahr",
      "Pathankot",
      "Patiala",
      "Pattran",
      "Patti",
      "Phagwara",
      "Phillaur",
      "Qadian",
      "Raikot",
      "Rajpura",
      "RampuraPhul",
      "Rupnagar",
      "Samana",
      "Sangrur",
      "SirhindFatehgarhSahib",
      "Sujanpur",
      "Sunam",
      "Talwara",
      "TarnTaran",
      "UrmarTanda",
      "Zira",
      "Zirakpur",
    ],
  },
  {
    state: "Chandigarh",
    gstcode: "04",
    city: ["Chandigarh"],
  },
  {
    state: "Uttarakhand",
    gstcode: "05",
    city: [
      "Bageshwar",
      "Dehradun",
      "HaldwanicumKathgodam",
      "Hardwar",
      "Kashipur",
      "Manglaur",
      "Mussoorie",
      "Nagla",
      "Nainital",
      "Pauri",
      "Pithoragarh",
      "Ramnagar",
      "Rishikesh",
      "Roorkee",
      "Rudrapur",
      "Sitarganj",
      "Srinagar",
      "Tehri",
    ],
  },
  {
    state: "Haryana",
    gstcode: "06",
    city: [
      "Bahadurgarh",
      "Bhiwani",
      "CharkhiDadri",
      "Faridabad",
      "Fatehabad",
      "Gohana",
      "Gurgaon",
      "Hansi",
      "Hisar",
      "Jind",
      "Kaithal",
      "Karnal",
      "Ladwa",
      "Mahendragarh",
      "MandiDabwali",
      "Narnaul",
      "Narwana",
      "Palwal",
      "Panchkula",
      "Panipat",
      "Pehowa",
      "Pinjore",
      "Rania",
      "Ratia",
      "Rewari",
      "Rohtak",
      "Safidon",
      "Samalkha",
      "Sarsod",
      "Shahbad",
      "Sirsa",
      "Sohna",
      "Sonipat",
      "Taraori",
      "Thanesar",
      "Tohana",
      "Yamunanagar",
    ],
  },
  {
    state: "Delhi",
    gstcode: "07",
    city: ["Delhi", "NewDelhi"],
  },
  {
    state: "Rajasthan",
    gstcode: "08",
    city: [
      "Ajmer",
      "Alwar",
      "Bikaner",
      "Bharatpur",
      "Bhilwara",
      "Jaipur",
      "Jodhpur",
      "Lachhmangarh",
      "Ladnu",
      "Lakheri",
      "Lalsot",
      "Losal",
      "Makrana",
      "Malpura",
      "Mandalgarh",
      "Mandawa",
      "Mangrol",
      "MertaCity",
      "MountAbu",
      "Nadbai",
      "Nagar",
      "Nagaur",
      "Nasirabad",
      "Nathdwara",
      "NeemKaThana",
      "Nimbahera",
      "Nohar",
      "Nokha",
      "Pali",
      "Phalodi",
      "Phulera",
      "Pilani",
      "Pilibanga",
      "Pindwara",
      "PiparCity",
      "Prantij",
      "Pratapgarh",
      "Raisinghnagar",
      "Rajakhera",
      "Rajaldesar",
      "RajgarhAlwar",
      "RajgarhChuru",
      "Rajsamand",
      "RamganjMandi",
      "Ramngarh",
      "Ratangarh",
      "Rawatbhata",
      "Rawatsar",
      "Reengus",
      "Sadri",
      "Sadulshahar",
      "Sadulpur",
      "Sagwara",
      "Sambhar",
      "Sanchore",
      "Sangaria",
      "Sardarshahar",
      "SawaiMadhopur",
      "Shahpura",
      "Shahpura",
      "Sheoganj",
      "Sikar",
      "Sirohi",
      "Sojat",
      "SriMadhopur",
      "Sujangarh",
      "Sumerpur",
      "Suratgarh",
      "Taranagar",
      "Todabhim",
      "Todaraisingh",
      "Tonk",
      "Udaipur",
      "Udaipurwati",
      "Vijainagar,Ajmer",
    ],
  },
  {
    state: "Uttar Pradesh",
    gstcode: "09",
    city: [
      "Achhnera",
      "Agra",
      "Aligarh",
      "Allahabad",
      "Amroha",
      "Azamgarh",
      "Bahraich",
      "Chandausi",
      "Etawah",
      "Firozabad",
      "FatehpurSikri",
      "Hapur",
      "Hardoi",
      "Jhansi",
      "Kalpi",
      "Kanpur",
      "Khair",
      "Laharpur",
      "Lakhimpur",
      "LalGopalganjNindaura",
      "Lalitpur",
      "Lalganj",
      "Lar",
      "Loni",
      "Lucknow",
      "Mathura",
      "Meerut",
      "Modinagar",
      "Moradabad",
      "Nagina",
      "Najibabad",
      "Nakur",
      "Nanpara",
      "Naraura",
      "NaugawanSadat",
      "Nautanwa",
      "Nawabganj",
      "Nehtaur",
      "Niwai",
      "Noida",
      "Noorpur",
      "Obra",
      "Orai",
      "Padrauna",
      "PaliaKalan",
      "Parasi",
      "Phulpur",
      "Pihani",
      "Pilibhit",
      "Pilkhuwa",
      "Powayan",
      "Pukhrayan",
      "Puranpur",
      "Purquazi",
      "Purwa",
      "RaeBareli",
      "Rampur",
      "RampurManiharan",
      "RampurManiharan",
      "Rasra",
      "Rath",
      "Renukoot",
      "Reoti",
      "Robertsganj",
      "Rudauli",
      "Rudrapur",
      "Sadabad",
      "Safipur",
      "Saharanpur",
      "Sahaspur",
      "Sahaswan",
      "Sahawar",
      "Sahjanwa",
      "Saidpur",
      "Sambhal",
      "Samdhan",
      "Samthar",
      "Sandi",
      "Sandila",
      "Sardhana",
      "Seohara",
      "Shahabad,Hardoi",
      "Shahabad,Rampur",
      "Shahganj",
      "Shahjahanpur",
      "Shamli",
      "Shamsabad,Agra",
      "Shamsabad,Farrukhabad",
      "Sherkot",
      "Shikarpur,Bulandshahr",
      "Shikohabad",
      "Shishgarh",
      "Siana",
      "Sikanderpur",
      "SikandraRao",
      "Sikandrabad",
      "Sirsaganj",
      "Sirsi",
      "Sitapur",
      "Soron",
      "Suar",
      "Sultanpur",
      "Sumerpur",
      "Tanda",
      "Thakurdwara",
      "ThanaBhawan",
      "Tilhar",
      "Tirwaganj",
      "Tulsipur",
      "Tundla",
      "Ujhani",
      "Unnao",
      "Utraula",
      "Varanasi",
      "Vrindavan",
      "Warhapur",
      "Zaidpur",
      "Zamania",
    ],
  },
  {
    state: "Bihar",
    gstcode: "10",
    city: [
      "Araria",
      "Arrah",
      "Arwal",
      "Asarganj",
      "Aurangabad",
      "Bagaha",
      "Barh",
      "Begusarai",
      "Bettiah",
      "Bhabua",
      "Bhagalpur",
      "Buxar",
      "Chhapra",
      "Darbhanga",
      "DehrionSone",
      "Dumraon",
      "Forbesganj",
      "Gaya",
      "Gopalganj",
      "Hajipur",
      "Jamalpur",
      "Jamui",
      "Jehanabad",
      "Katihar",
      "Kishanganj",
      "Lakhisarai",
      "Lalganj",
      "Madhepura",
      "Madhubani",
      "Maharajganj",
      "MahnarBazar",
      "Makhdumpur",
      "Maner",
      "Manihari",
      "Marhaura",
      "Masaurhi",
      "Mirganj",
      "Mokameh",
      "Motihari",
      "Motipur",
      "Munger",
      "Murliganj",
      "Muzaffarpur",
      "Narkatiaganj",
      "Naugachhia",
      "Nawada",
      "Nokha",
      "Patna",
      "Piro",
      "Purnia",
      "Rafiganj",
      "Rajgir",
      "Ramnagar",
      "RaxaulBazar",
      "Revelganj",
      "Rosera",
      "Saharsa",
      "Samastipur",
      "Sasaram",
      "Sheikhpura",
      "Sheohar",
      "Sherghati",
      "Silao",
      "Sitamarhi",
      "Siwan",
      "Sonepur",
      "Sugauli",
      "Sultanganj",
      "Supaul",
      "Warisaliganj",
    ],
  },
  {
    state: "Sikkim",
    gstcode: "11",
    city: [
      "Gangtok ",
      "Lachung",
      "Pelling",
      "Namchi",
      "Ravangla",
      "Yuksom",
      "Lachen",
      "Yumesamdong",
      "Rinchenpong",
      "Monasterederumteck",
      "Jorethang",
      "Yangtey",
      "Rumtek",
      "Singtam",
      "UphperTadong",
    ],
  },
  {
    state: "Arunachal Pradesh",
    gstcode: "12",
    city: ["Naharlagun", "Pasighat"],
  },
  {
    state: "Nagaland",
    gstcode: "13",
    city: ["Dimapur", "Kohima", "Mokokchung", "Tuensang", "Wokha", "Zunheboto"],
  },
  {
    state: "Manipur",
    gstcode: "14",
    city: ["Imphal", "Lilong", "MayangImphal", "Thoubal"],
  },
  {
    state: "Mizoram",
    gstcode: "15",
    city: ["Aizawl", "Lunglei", "Saiha"],
  },
  {
    state: "Tripura",
    gstcode: "16",
    city: [
      "Agartala",
      "Belonia",
      "Dharmanagar",
      "Kailasahar",
      "Khowai",
      "Pratapgarh",
      "Udaipur",
    ],
  },
  {
    state: "Meghlaya",
    gstcode: "17",
    city: ["Nongstoin", "Shillong", "Tura"],
  },
  {
    state: "Assam",
    gstcode: "18",
    city: [
      "Barpeta",
      "BongaigaonCity",
      "Dhubri",
      "Dibrugarh",
      "Diphu",
      "Goalpara",
      "Guwahati",
      "Jorhat",
      "Karimganj",
      "Lanka",
      "Lumding",
      "Mangaldoi",
      "Mankachar",
      "Margherita",
      "Mariani",
      "Marigaon",
      "Nagaon",
      "Nalbari",
      "NorthLakhimpur",
      "Rangia",
      "Sibsagar",
      "Silapathar",
      "Silchar",
      "Tezpur",
      "Tinsukia",
    ],
  },
  {
    state: "West Bengal",
    gstcode: "19",
    city: [
      "Adra",
      "Alipurduar",
      "Arambagh",
      "Asansol",
      "Baharampur",
      "Balurghat",
      "Bankura",
      "Darjiling",
      "EnglishBazar",
      "Gangarampur",
      "Habra",
      "HugliChinsurah",
      "Jalpaiguri",
      "Jhargram",
      "Kalimpong",
      "Kharagpur",
      "Kolkata",
      "Mainaguri",
      "Malda",
      "Mathabhanga",
      "Medinipur",
      "Memari",
      "Monoharpur",
      "Murshidabad",
      "Nabadwip",
      "Naihati",
      "Panchla",
      "Pandua",
      "PaschimPunropara",
      "Purulia",
      "Raghunathpur",
      "Raghunathganj",
      "Raiganj",
      "Rampurhat",
      "Ranaghat",
      "Sainthia",
      "Santipur",
      "Siliguri",
      "Sonamukhi",
      "Srirampore",
      "Suri",
      "Taki",
      "Tamluk",
      "Tarakeswar",
    ],
  },
  {
    state: "Jharkhand",
    gstcode: "20",
    city: [
      "Adityapur",
      "BokaroSteelCity",
      "Chaibasa",
      "Chatra",
      "Chirkunda",
      "Medininagar",
      "Deoghar",
      "Dhanbad",
      "Dumka",
      "Giridih",
      "Gumia",
      "Hazaribag",
      "Jamshedpur",
      "JhumriTilaiya",
      "Lohardaga",
      "Madhupur",
      "Mihijam",
      "Musabani",
      "Pakaur",
      "Patratu",
      "Phusro",
      "Ramgarh",
      "Ranchi",
      "Sahibganj",
      "Saunda",
      "Simdega",
      "TenudamcumKathhara",
    ],
  },
  {
    state: "Odisha",
    gstcode: "21",
    city: [
      "Balangir",
      "BaleshwarTown",
      "Barbil",
      "Bargarh",
      "BaripadaTown",
      "Bhadrak",
      "Bhawanipatna",
      "Bhubaneswar",
      "Brahmapur",
      "Byasanagar",
      "Cuttack",
      "Dhenkanal",
      "Jatani",
      "Jharsuguda",
      "Kendrapara",
      "Kendujhar",
      "Malkangiri",
      "Nabarangapur",
      "Paradip",
      "Parlakhemundi",
      "Pattamundai",
      "Phulabani",
      "Puri",
      "Rairangpur",
      "Rajagangapur",
      "Raurkela",
      "Rayagada",
      "Sambalpur",
      "Soro",
      "Sunabeda",
      "Sundargarh",
      "Talcher",
      "Tarbha",
      "Titlagarh",
    ],
  },
  {
    state: "Chhattisgarh",
    gstcode: "22",
    city: [
      "Ambikapur",
      "Bhatapara",
      "BhilaiNagar",
      "Bilaspur",
      "Chirmiri",
      "DalliRajhara",
      "Dhamtari",
      "Durg",
      "Jagdalpur",
      "Korba",
      "Mahasamund",
      "Manendragarh",
      "Mungeli",
      "NailaJanjgir",
      "Raigarh",
      "Raipur",
      "Rajnandgaon",
      "Sakti",
      "TildaNewra",
    ],
  },
  {
    state: "Madhya Pradesh",
    gstcode: "23",
    city: [
      "Alirajpur",
      "AshokNagar",
      "Balaghat",
      "Bhopal",
      "Ganjbasoda",
      "Gwalior",
      "Indore",
      "Itarsi",
      "Jabalpur",
      "Lahar",
      "Maharajpur",
      "Mahidpur",
      "Maihar",
      "MalajKhand",
      "Manasa",
      "Manawar",
      "Mandideep",
      "Mandla",
      "Mandsaur",
      "Mauganj",
      "MhowCantonment",
      "Mhowgaon",
      "Morena",
      "Multai",
      "Mundi",
      "MurwaraKatni",
      "Nagda",
      "Nainpur",
      "Narsinghgarh",
      "Narsinghgarh",
      "Neemuch",
      "Nepanagar",
      "Niwari",
      "Nowgong",
      "NowrozabadKhodargama",
      "Pachore",
      "Pali",
      "Panagar",
      "Pandhurna",
      "Panna",
      "Pasan",
      "Pipariya",
      "Pithampur",
      "Porsa",
      "Prithvipur",
      "RaghogarhVijaypur",
      "Rahatgarh",
      "Raisen",
      "Rajgarh",
      "Ratlam",
      "Rau",
      "Rehli",
      "Rewa",
      "Sabalgarh",
      "Sagar",
      "Sanawad",
      "Sarangpur",
      "Sarni",
      "Satna",
      "Sausar",
      "Sehore",
      "Sendhwa",
      "Seoni",
      "SeoniMalwa",
      "Shahdol",
      "Shajapur",
      "Shamgarh",
      "Sheopur",
      "Shivpuri",
      "Shujalpur",
      "Sidhi",
      "Sihora",
      "Singrauli",
      "Sironj",
      "Sohagpur",
      "Tarana",
      "Tikamgarh",
      "Ujjain",
      "Umaria",
      "Vidisha",
      "Vijaypur",
      "WaraSeoni",
    ],
  },
  {
    state: "Gujarat",
    gstcode: "24",
    city: [
      "Adalaj",
      "Ahmedabad",
      "Amreli",
      "Anand",
      "Anjar",
      "Ankleshwar",
      "Bharuch",
      "Bhavnagar",
      "Bhuj",
      "Chhapra",
      "Deesa",
      "Dhoraji",
      "Godhra",
      "Jamnagar",
      "Kadi",
      "Kapadvanj",
      "Keshod",
      "Khambhat",
      "Lathi",
      "Limbdi",
      "Lunawada",
      "Mahesana",
      "Mahuva",
      "Manavadar",
      "Mandvi",
      "Mangrol",
      "Mansa",
      "Mahemdabad",
      "Modasa",
      "Morvi",
      "Nadiad",
      "Navsari",
      "Padra",
      "Palanpur",
      "Palitana",
      "Pardi",
      "Patan",
      "Petlad",
      "Porbandar",
      "Radhanpur",
      "Rajkot",
      "Rajpipla",
      "Rajula",
      "Ranavav",
      "Rapar",
      "Salaya",
      "Sanand",
      "Savarkundla",
      "Sidhpur",
      "Sihor",
      "Songadh",
      "Surat",
      "Talaja",
      "Thangadh",
      "Tharad",
      "Umbergaon",
      "Umreth",
      "Una",
      "Unjha",
      "Upleta",
      "Vadnagar",
      "Vadodara",
      "Valsad",
      "Vapi",
      "Vapi",
      "Veraval",
      "Vijapur",
      "Viramgam",
      "Visnagar",
      "Vyara",
      "Wadhwan",
      "Wankaner",
    ],
  },
  {
    state: "Dadra and Nagar Haveli",
    gstcode: "26",
    city: ["Silvassa"],
  },
  {
    state: "Maharashtra",
    gstcode: "27",
    city: [
      "Ahmednagar",
      "Akola",
      "Akot",
      "Amalner",
      "Ambejogai",
      "Amravati",
      "Anjangaon",
      "Arvi",
      "Aurangabad",
      "Bhiwandi",
      "Dhule",
      "KalyanDombivali",
      "Ichalkaranji",
      "KalyanDombivali",
      "Karjat",
      "Latur",
      "Loha",
      "Lonar",
      "Lonavla",
      "Mahad",
      "Malegaon",
      "Malkapur",
      "Mangalvedhe",
      "Mangrulpir",
      "Manjlegaon",
      "Manmad",
      "Manwath",
      "Mehkar",
      "Mhaswad",
      "MiraBhayandar",
      "Morshi",
      "Mukhed",
      "Mul",
      "Mumbai",
      "Murtijapur",
      "Nagpur",
      "NandedWaghala",
      "Nandgaon",
      "Nandura",
      "Nandurbar",
      "Narkhed",
      "Nashik",
      "NaviMumbai",
      "Nawapur",
      "Nilanga",
      "Osmanabad",
      "Ozar",
      "Pachora",
      "Paithan",
      "Palghar",
      "Pandharkaoda",
      "Pandharpur",
      "Panvel",
      "Parbhani",
      "Parli",
      "Partur",
      "Pathardi",
      "Pathri",
      "Patur",
      "Pauni",
      "Pen",
      "Phaltan",
      "Pulgaon",
      "Pune",
      "Purna",
      "Pusad",
      "Rahuri",
      "Rajura",
      "Ramtek",
      "Ratnagiri",
      "Raver",
      "Risod",
      "Sailu",
      "Sangamner",
      "Sangli",
      "Sangole",
      "Sasvad",
      "Satana",
      "Satara",
      "Savner",
      "Sawantwadi",
      "Shahade",
      "Shegaon",
      "Shendurjana",
      "Shirdi",
      "ShirpurWarwade",
      "Shirur",
      "Shrigonda",
      "Shrirampur",
      "Sillod",
      "Sinnar",
      "Solapur",
      "Soyagaon",
      "TalegaonDabhade",
      "Talode",
      "Tasgaon",
      "Thane",
      "Tirora",
      "Tuljapur",
      "Tumsar",
      "Uchgaon",
      "Udgir",
      "Umarga",
      "Umarkhed",
      "Umred",
      "Uran",
      "UranIslampur",
      "VadgaonKasba",
      "Vaijapur",
      "VasaiVirar",
      "Vita",
      "WadgaonRoad",
      "Wai",
      "Wani",
      "Wardha",
      "Warora",
      "Warud",
      "Washim",
      "Yavatmal",
      "Yawal",
      "Yevla",
    ],
  },
  {
    state: "Andhra Pradesh",
    gstcode: "28",
    city: [
      "Adoni",
      "Amalapuram",
      "Anakapalle",
      "Anantapur",
      "Bapatla",
      "Bheemunipatnam",
      "Bhimavaram",
      "Bobbili",
      "Chilakaluripet",
      "Chirala",
      "Chittoor",
      "Dharmavaram",
      "Eluru",
      "Gooty",
      "Gudivada",
      "Gudur",
      "Guntakal",
      "Guntur",
      "Hindupur",
      "Jaggaiahpet",
      "Jammalamadugu",
      "Kadapa",
      "Kadiri",
      "Kakinada",
      "Kandukur",
      "Kavali",
      "Kovvur",
      "Kurnool",
      "Macherla",
      "Machilipatnam",
      "Madanapalle",
      "Mandapeta",
      "Markapur",
      "Nagari",
      "Naidupet",
      "Nandyal",
      "Narasapuram",
      "Narasaraopet",
      "Narsipatnam",
      "Nellore",
      "Nidadavole",
      "Nuzvid",
      "Ongole",
      "Palacole",
      "PalasaKasibugga",
      "Parvathipuram",
      "Pedana",
      "Peddapuram",
      "Pithapuram",
      "Ponnur",
      "Proddatur",
      "Punganur",
      "Puttur",
      "Rajahmundry",
      "Rajam",
      "Rajampet",
      "Ramachandrapuram",
      "Rayachoti",
      "Rayadurg",
      "Renigunta",
      "Repalle",
      "Salur",
      "Samalkot",
      "Sattenapalle",
      "Srikakulam",
      "Srikalahasti",
      "SrisailamProjectTownship",
      "Sullurpeta",
      "Tadepalligudem",
      "Tadpatri",
      "Tanuku",
      "Tenali",
      "Tirupati",
      "Tiruvuru",
      "Tuni",
      "Uravakonda",
      "Venkatagiri",
      "Vijayawada",
      "Vinukonda",
      "Visakhapatnam",
      "Vizianagaram",
      "Yemmiganur",
      "Yerraguntla",
    ],
  },
  {
    state: "Karnataka",
    gstcode: "29",
    city: [
      "Adyar",
      "Afzalpur",
      "Arsikere",
      "Athni",
      "Bengaluru",
      "Belagavi",
      "Ballari",
      "Chikkamagaluru",
      "Davanagere",
      "Gokak",
      "HubliDharwad",
      "Karwar",
      "Kolar",
      "Lakshmeshwar",
      "Lingsugur",
      "Maddur",
      "Madhugiri",
      "Madikeri",
      "Magadi",
      "Mahalingapura",
      "Malavalli",
      "Malur",
      "Mandya",
      "Mangaluru",
      "Manvi",
      "Mudalagi",
      "Mudabidri",
      "Muddebihal",
      "Mudhol",
      "Mulbagal",
      "Mundargi",
      "Mysore",
      "Nanjangud",
      "Nargund",
      "Navalgund",
      "Nelamangala",
      "Pavagada",
      "Piriyapatna",
      "Puttur",
      "RabkaviBanhatti",
      "Raayachuru",
      "Ranebennuru",
      "Ramanagaram",
      "Ramdurg",
      "Ranibennur",
      "RobertsonPet",
      "Ron",
      "Sadalagi",
      "Sagara",
      "Sakaleshapura",
      "Sindagi",
      "Sanduru",
      "Sankeshwara",
      "SaundattiYellamma",
      "Savanur",
      "Sedam",
      "Shahabad",
      "Shahpur",
      "Shiggaon",
      "Shikaripur",
      "Shivamogga",
      "Surapura",
      "Shrirangapattana",
      "Sidlaghatta",
      "Sindhagi",
      "Sindhnur",
      "Sira",
      "Sirsi",
      "Siruguppa",
      "Srinivaspur",
      "Tarikere",
      "Tekkalakote",
      "Terdal",
      "Talikota",
      "Tiptur",
      "Tumkur",
      "Udupi",
      "Vijayapura",
      "Wadi",
      "Yadgir",
    ],
  },
  {
    state: "Goa",
    gstcode: "30",
    city: ["Mapusa", "Margao", "Marmagao", "Panaji"],
  },
  {
    state: "Lakshdeep",
    gstcode: "31",
    city: ["Kavaratti ", "Amini"],
  },
  {
    state: "Kerala",
    gstcode: "32",
    city: [
      "Adoor",
      "Alappuzha",
      "Attingal",
      "Chalakudy",
      "Changanassery",
      "Cherthala",
      "ChitturThathamangalam",
      "Guruvayoor",
      "Kanhangad",
      "Kannur",
      "Kasaragod",
      "Kayamkulam",
      "Kochi",
      "Kodungallur",
      "Kollam",
      "Kottayam",
      "Kozhikode",
      "Kunnamkulam",
      "Malappuram",
      "Mattannur",
      "Mavelikkara",
      "Mavoor",
      "Muvattupuzha",
      "Nedumangad",
      "Neyyattinkara",
      "Nilambur",
      "Ottappalam",
      "Palai",
      "Palakkad",
      "Panamattom",
      "Panniyannur",
      "Pappinisseri",
      "Paravoor",
      "Pathanamthitta",
      "Peringathur",
      "Perinthalmanna",
      "Perumbavoor",
      "Ponnani",
      "Punalur",
      "Puthuppally",
      "Koyilandy",
      "Shoranur",
      "Taliparamba",
      "Thiruvalla",
      "Thiruvananthapuram",
      "Thodupuzha",
      "Thrissur",
      "Tirur",
      "Vaikom",
      "Varkala",
      "Vatakara",
    ],
  },
  {
    state: "Tamil Nadu",
    gstcode: "33",
    city: [
      "Arakkonam",
      "Aruppukkottai",
      "Chennai",
      "Coimbatore",
      "Erode",
      "Gobichettipalayam",
      "Kancheepuram",
      "Karur",
      "Lalgudi",
      "Madurai",
      "Manachanallur",
      "Nagapattinam",
      "Nagercoil",
      "Namagiripettai",
      "Namakkal",
      "NandivaramGuduvancheri",
      "Nanjikottai",
      "Natham",
      "Nellikuppam",
      "NeyveliTS",
      "OValley",
      "Oddanchatram",
      "PNPatti",
      "Pacode",
      "Padmanabhapuram",
      "Palani",
      "Palladam",
      "Pallapatti",
      "Pallikonda",
      "Panagudi",
      "Panruti",
      "Paramakudi",
      "Parangipettai",
      "Pattukkottai",
      "Perambalur",
      "Peravurani",
      "Periyakulam",
      "Periyasemur",
      "Pernampattu",
      "Pollachi",
      "Polur",
      "Ponneri",
      "Pudukkottai",
      "Pudupattinam",
      "Puliyankudi",
      "Punjaipugalur",
      "Ranipet",
      "Rajapalayam",
      "Ramanathapuram",
      "Rameshwaram",
      "Rasipuram",
      "Salem",
      "Sankarankoil",
      "Sankari",
      "Sathyamangalam",
      "Sattur",
      "Shenkottai",
      "Sholavandan",
      "Sholingur",
      "Sirkali",
      "Sivaganga",
      "Sivagiri",
      "Sivakasi",
      "Srivilliputhur",
      "Surandai",
      "Suriyampalayam",
      "Tenkasi",
      "Thammampatti",
      "Thanjavur",
      "Tharamangalam",
      "Tharangambadi",
      "TheniAllinagaram",
      "Thirumangalam",
      "Thirupuvanam",
      "Thiruthuraipoondi",
      "Thiruvallur",
      "Thiruvarur",
      "Thuraiyur",
      "Tindivanam",
      "Tiruchendur",
      "Tiruchengode",
      "Tiruchirappalli",
      "Tirukalukundram",
      "Tirukkoyilur",
      "Tirunelveli",
      "Tirupathur",
      "Tirupathur",
      "Tiruppur",
      "Tiruttani",
      "Tiruvannamalai",
      "Tiruvethipuram",
      "Tittakudi",
      "Udhagamandalam",
      "Udumalaipettai",
      "Unnamalaikadai",
      "Usilampatti",
      "Uthamapalayam",
      "Uthiramerur",
      "Vadakkuvalliyur",
      "Vadalur",
      "Vadipatti",
      "Valparai",
      "Vandavasi",
      "Vaniyambadi",
      "Vedaranyam",
      "Vellakoil",
      "Vellore",
      "Vikramasingapuram",
      "Viluppuram",
      "Virudhachalam",
      "Virudhunagar",
      "Viswanatham",
    ],
  },
  {
    state: "Puducherry",
    gstcode: "34",
    city: ["Karaikal", "Mahe", "Pondicherry", "Yanam"],
  },
  {
    state: "Andaman and Nicobar Islands",
    gstcode: "35",
    city: ["PortBlair"],
  },
  {
    state: "Telangana",
    gstcode: "36",
    city: [
      "Adilabad",
      "Bellampalle",
      "Bhadrachalam",
      "Bhainsa",
      "Bhongir",
      "Bodhan",
      "Farooqnagar",
      "Gadwal",
      "Hyderabad",
      "Jagtial",
      "Jangaon",
      "Kagaznagar",
      "Kamareddy",
      "Karimnagar",
      "Khammam",
      "Koratla",
      "Kothagudem",
      "Kyathampalle",
      "Mahbubnagar",
      "Mancherial",
      "Mandamarri",
      "Manuguru",
      "Medak",
      "Miryalaguda",
      "Nagarkurnool",
      "Narayanpet",
      "Nirmal",
      "Nizamabad",
      "Palwancha",
      "Ramagundam",
      "Sadasivpet",
      "Sangareddy",
      "Siddipet",
      "Sircilla",
      "Suryapet",
      "Tandur",
      "Vikarabad",
      "Wanaparthy",
      "Warangal",
      "Yellandu",
    ],
  },
  {
    state: "Ladakh",
    gstcode: "38",
    city: ["Kargil ", "Leh "],
  },
];
export const Apply = (props) => {
  let minimumDate = new Date();
  minimumDate = minimumDate.setFullYear(minimumDate.getFullYear() - 18);
  const [page, setPagenumber] = useState(1);
  const [jd, setJd] = useState();
  const { jdId, C_JD_ID, desig } = useContext(jdContext);
  const [file, setFile] = useState([]);
  const [fileErr, setFileErr] = useState(false);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    if (!jdId) {
      history.push({
        pathname: "/openings",
      });
    } else {
      setJd(jdId);
      setLoad(false);
    }
  }, [jdId]);
  const [email, setEmail] = useState("");
  const [sameEmail, setSameEmail] = useState(false);
  // const [samePhone, setSamePhone] = useState(false);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [samePhone, setSamePhone] = useState(false);
  const [c_ctc, setC_ctc] = useState("");
  const [e_ctc, setE_ctc] = useState("");
  const [gender, setGender] = useState("");
  const [n_periode, setn_periode] = useState("");
  const [err, setErr] = useState([]);
  const [errTwo, setErrTwo] = useState([]);
  const [can_id, setCan_id] = useState();
  const [size, setSizeErr] = useState(false);
  const [stateShow, setStateShow] = useState(false);
  const [statess, setStatess] = useState(stateArray);
  const [candidateState, setCandidateState] = useState("");
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState("");
  const [showCitySelect, setShowCitySelect] = useState(false);
  const [date, setDOB] = useState();
  const [dateErr, setDateErr] = useState(false);
  const [marriedStatus, setMarriedStatus] = useState("");
  const [state, setStates] = useState("");
  const [source, setSource] = useState("");
  const [experience, setExperience] = useState("");
  const [radio, setRadio] = useState(0);
  const [mobileDigit, setMobileDigit] = useState(false);
  const [clicked, setClicked] = useState(false);
  let validation_input = [
    {
      tagid: "email",
      text: email,
      regex_name: "email",
      required: true,
      errmsg: "Please enter valid Email.",
      allow_numbers: true,
      other_chars: true,
    },
    {
      tagid: "name",
      text: name,
      regex_name: "text",
      required: true,
      errmsg: "Please enter valid First Name.",
      allow_numbers: false,
    },
    {
      tagid: "lastname",
      text: lastName,
      regex_name: "text",
      required: true,
      errmsg: "Please enter valid Last Name.",
      allow_numbers: false,
    },

    {
      tagid: "phone",
      text: phone,
      regex_name: "exact_x_digits",
      required: true,
      errmsg: "Enter a valid Phone Number.",
      x_count: 10,
      // other_chars: false,
      // allow_numbers: true,
      // min: 10,
      // max: 10,
    },
    {
      tagid: "gender",
      text: gender === "" ? "" : gender,
      regex_name: "free_text",
      required: true,
      errmsg: "Please select Gender.",
    },
  ];
  let pageTwo = [
    {
      tagid: "c_ctc",
      text: c_ctc,
      regex_name: "any_number",
      required: true,
      errmsg: "Enter valid CTC.",
      allow_decimal: true,
      allow_negative: false,
    },
    {
      tagid: "e_ctc",
      text: e_ctc == "0" ? "" : e_ctc,
      regex_name: "any_number",
      required: true,
      errmsg: "Enter valid CTC.",
      allow_decimal: true,
      allow_negative: false,
    },

    {
      tagid: "noticepd",
      text:
        n_periode === "select"
          ? ""
          : n_periode === "7"
          ? "07"
          : n_periode == "0"
          ? "00"
          : n_periode,
      regex_name: "free_text",
      required: true,
      errmsg: "Please select Notice Period.",
    },
    {
      tagid: "exp",
      text: experience,
      regex_name: "any_number",
      required: true,
      errmsg: "Enter experience",
      allow_decimal: true,
      allow_negative: false,
    },
    {
      tagid: "source",
      text: source,
      regex_name: "text",
      required: true,
      errmsg: "Please select the Source.",
    },
  ];
  let pagethree = [
    {
      tagid: "city",
      text: city,
      regex_name: "text",
      required: true,
      errmsg: "Please select City.",
    },
    {
      tagid: "candidateState",
      text: candidateState,
      regex_name: "text",
      required: true,
      errmsg: "Please select State.",
    },
    {
      tagid: "marriedStatus",
      text: marriedStatus === "select" ? "" : marriedStatus,
      regex_name: "free_text",
      required: true,
      errmsg: "Please select maritial status.",
    },
  ];
  let history = useHistory();
  const submit = () => {
    if (
      validate(validation_input).length !== 0 ||
      validate(pageTwo).length !== 0
    ) {
      setErr(validate(validation_input));
      setErrTwo(validate(pageTwo));
    } else {
      let obj = {
        name: name,
        email: email,
        designation: desig,
        dob: 0,
        phone: phone,
        notice_periode: n_periode,
        current_ctc: c_ctc,
        expected_ctc: e_ctc,
        source: "",
        gender: "",
        marital_status: "",
        city: "",
        state: "",
        custom_jd_id: C_JD_ID,
      };

      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/openings/apply`, obj, {
          headers: { authorization: localStorage.getItem("token"), id: jd },
        })
        .then((res) => {
          if (res) {
            if (res.data.token) {
              localStorage.removeItem("token");
              localStorage.removeItem("refreshToken");
              window.location.reload();
            } else {
              history.push({
                pathname: "/opening",
                state: true,
              });
            }
          }
        });
    }
  };
  function realTimeValidation(input, tagid) {
    let valid = validate(input);
    let check = false;
    if (valid[0]) {
      err.forEach((obj) => {
        if (obj.tagid === valid[0].tagid) {
          check = true;
        }
      });
      if (!check) {
        setErr((arr) => {
          return [...arr, ...valid];
        });
      }
    } else {
      check = true;
      let newErr = err.filter((obj) => {
        if (obj.tagid !== tagid) {
          return obj;
        }
      });
      setErr(newErr);
    }
  }
  function realTimeValidationTwo(input, tagid) {
    let valid = validate(input);
    let check = false;
    if (valid[0]) {
      errTwo.forEach((obj) => {
        if (obj.tagid === valid[0].tagid) {
          check = true;
        }
      });
      if (!check) {
        setErrTwo((arr) => {
          return [...arr, ...valid];
        });
      }
    } else {
      check = true;
      let newErr = errTwo.filter((obj) => {
        if (obj.tagid !== tagid) {
          return obj;
        }
      });
      setErrTwo(newErr);
    }
  }
  function findEmail(checkEmail) {
    // console.log("hitting");
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/candidates/findEmail`, {
        headers: {
          authorization: localStorage.getItem("token"),
          name: checkEmail,
          location: "addCandidate",
        },
      })
      .then((response) => {
        if (response) {
          setSameEmail(response.data.exist);
        }
      });
  }
  function findPhone(phone) {
    // console.log("hitting");
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/candidates/findPhone`, {
        headers: {
          authorization: localStorage.getItem("token"),
          name: phone,
          location: "addCandidate",
        },
      })
      .then((response) => {
        if (response) {
          setSamePhone(response.data.exist);
        }
      });
  }
  function pageOne() {
    if (
      validate(validation_input).length !== 0 ||
      sameEmail ||
      mobileDigit ||
      samePhone
    ) {
      setErr(validate(validation_input));
    } else {
      setPagenumber(3);
    }
  }
  function pageThree() {
    if (validate(pagethree).length !== 0 || !date) {
      if (!date) {
        setDateErr(true);
      } else {
        setDateErr(false);
      }
      setErr(validate(pagethree));
    } else {
      setPagenumber(2);
    }
  }
  function pageTwoValidation() {
    if (validate(pageTwo).length !== 0 || size || !file[0]) {
      if (!file[0]) {
        setFileErr(true);
      } else {
        setFileErr(false);
      }
      setErrTwo(validate(pageTwo));
    } else {
      setClicked(true);
      let obj = {
        name: name+" "+lastName,
        email: email,
        designation: desig,
        dob: date.getTime(),
        date,
        phone: phone,
        notice_periode: n_periode,
        current_ctc: c_ctc,
        expected_ctc: e_ctc,
        source: source,
        experience,
        gender: gender,
        marital_status: marriedStatus,
        city: city,
        state: candidateState,
        custom_jd_id: C_JD_ID,
        can_id,
        id: jd,
      };
      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/openings/apply`, obj, {
          headers: {
            authorization: localStorage.getItem("token"),
            location: "login",
            date: date,
          },
        })
        .then((res) => {
          if (res) {
            if (res.data.token) {
              localStorage.removeItem("token");
              localStorage.removeItem("refreshToken");
              window.location.reload();
            } else {
              // setCan_id(res.data.id)

              const formData = new FormData();
              if (file[0]) {
                formData.append("document_type", "Resume");
                formData.append("location", "openPage");
                formData.append("candidate", res.data.id);
                formData.append("documents", file[0]);
              }
              if (file[0]) {
                axios
                  .post(
                    `${process.env.REACT_APP_API_BASE_URL}/document/create`,
                    formData,
                    {
                      headers: {
                        authorization: localStorage.getItem("token"),
                        location: "login",
                      },
                    }
                  )
                  .then((res) => {
                    if (res) {
                      if (res.data.token) {
                        localStorage.setItem("token", "token");
                        window.location.reload();
                      } else {
                        setFile([]);
                        swal({
                          title: "Thank You!",
                          text: "We have recieved your Job Application",
                          icon: "success",
                          button: true,
                          closeOnClickOutside: false,
                        }).then((ok) => {
                          props.value.setApply(true);
                          props.value.setJobCard("d-none");
                          props.value.setView("12");
                          props.value.SetCardDisplay("grid");
                          props.value.setCardWidth("");
                        });
                      }
                    }
                  });
              } else {
                setE_ctc("");
                setC_ctc("");
                setn_periode("");
                setExperience('')
                setFile([]);
                setFile([]);
                swal({
                  title: "Thank You!",
                  text: "We have recieved your Job Application",
                  icon: "success",
                  button: true,
                  closeOnClickOutside: false,
                }).then((ok) => {
                  props.value.setApply(true);
                });
              }
            }
          }
        });
    }
  }
  const onStateSelect = (id) => {
    let obj = {};
    statess.map((st) => {
      if (st.gstcode === id) {
        obj = st;
      } else {
        return null;
      }
    });
    setCandidateState(obj.state);
    obj.city.sort(function (a, b) {
      return a.localeCompare(b);
    });
    setCities(obj.city);
  };

  return load ? (
    <Loading></Loading>
  ) : (
    <CRow
      className={" info_details"}
      style={{
        overflowY: "scroll",
        maxHeight: "73vh",
        minHeight: "73vh",
        color: "black",
      }}
    >
      <CCol
        xs="12"
        // style={{ background: " rgba(255,255,255,0.5)",minHeight:"100%" }}
        style={{ minHeight: "100%" }}
        className={"rounded info_details"}
      >
        {page == 1 && (
          <CCol
            style={{ boxShadow: "0px 4px 15px #d8d8d8" }}
            className={"mx-auto border py-3 bg-white rounded"}
            xs="12"
            md="12"
            lg="12"
          >
            <p className="h5">Personal Info</p>
            <hr className="mt-1"></hr>
            <CRow>
              <CCol md="6" xs="12">
                <CLabel className={"mb-0 "}>First Name</CLabel>
                <CInput
                  value={name}
                  style={{ color: "black" }}
                  invalid={err.find((obj) => obj.tagid === "name")}
                  onChange={(e) => {
                    var splitStr = e.target.value.toLowerCase().split(" ");
                    for (var i = 0; i < splitStr.length; i++) {
                      // You do not need to check if i is larger than splitStr length, as your for does that for you
                      // Assign it back to the array
                      splitStr[i] =
                        splitStr[i].charAt(0).toUpperCase() +
                        splitStr[i].substring(1);
                    }
                    // Directly return the joined string
                    setName(splitStr.join(" ").trimStart());
                    // return splitStr.join(' ');
                    let input = [
                      {
                        tagid: "name",
                        text: e.target.value.trimStart(),
                        regex_name: "text",
                        required: true,
                        errmsg: "Please enter valid First Name",
                        allow_numbers: false,
                      },
                    ];
                    realTimeValidation(input, "name");
                  }}
                  placeholder="First Name"
                  className={"shadow-none"}
                ></CInput>
                {err.map((obj, index) => {
                  if (obj.tagid === "name") {
                    return <Err key={index} data={{ err: obj.errmsg }}></Err>;
                  } else {
                    return null;
                  }
                })}
              </CCol>
              <CCol md="6" xs="12">
                <CLabel className={"mb-0 "}>Last Name</CLabel>
                <CInput
                  value={lastName}
                  style={{ color: "black" }}
                  invalid={err.find((obj) => obj.tagid === "lastname")}
                  onChange={(e) => {
                    var splitStr = e.target.value.toLowerCase().split(" ");
                    for (var i = 0; i < splitStr.length; i++) {
                      // You do not need to check if i is larger than splitStr length, as your for does that for you
                      // Assign it back to the array
                      splitStr[i] =
                        splitStr[i].charAt(0).toUpperCase() +
                        splitStr[i].substring(1);
                    }
                    // Directly return the joined string
                    setLastName(splitStr.join(" ").trimStart());
                    // return splitStr.join(' ');
                    let input = [
                      {
                        tagid: "lastname",
                        text: e.target.value.trimStart(),
                        regex_name: "text",
                        required: true,
                        errmsg: "Please enter valid Last Name",
                        allow_numbers: false,
                      },
                    ];
                    realTimeValidation(input, "lastname");
                  }}
                  placeholder="Last Name"
                  className={"shadow-none"}
                ></CInput>
                {err.map((obj, index) => {
                  if (obj.tagid === "lastname") {
                    return <Err key={index} data={{ err: obj.errmsg }}></Err>;
                  } else {
                    return null;
                  }
                })}
              </CCol>
            </CRow>

            <CLabel className={"mb-0 mt-2"}>Email</CLabel>
            <CInput
              invalid={err.find((obj) => obj.tagid === "email")}
              placeholder="Enter Email"
              className={"shadow-none"}
              value={email}
              style={{ color: "black" }}
              onBlur={(e) => findEmail(e.target.value.toLowerCase().trim())}
              onChange={(e) => {
                setSameEmail(false);
                let input = [
                  {
                    tagid: "email",
                    text: e.target.value.toLowerCase().trim(),
                    regex_name: "email",
                    required: true,
                    errmsg: "Please enter valid Email.",
                    allow_numbers: true,
                    other_chars: true,
                  },
                ];
                setEmail(e.target.value.toLowerCase().trim());
                realTimeValidation(input, "email");
                findEmail(e.target.value.toLowerCase().trim());
              }}
            ></CInput>
            {sameEmail && (
              <div style={{}} className={"d-flex align-items-center mt-1"}>
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-exclamation-circle-fill text-warning"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                </svg> */}
                <p
                  style={{ fontSize: "0.7rem" }}
                  className={"ml-1 text-primary"}
                >
                  {" "}
                  ⚠️This Email is already registered. Please contact at
                  'hr@mobigic.com' for application.
                </p>
              </div>
            )}
            {err.map((obj, index) => {
              if (obj.tagid === "email") {
                return <Err key={index} data={{ err: obj.errmsg }}></Err>;
              } else {
                return null;
              }
            })}

            <CLabel className={"mb-0 mt-2"}>Mobile No.</CLabel>

            <CInput
              style={{ color: "black" }}
              placeholder="Enter Mobile Number"
              className={"shadow-none"}
              invalid={err.find((obj) => obj.tagid === "phone")}
              value={phone}
              onBlur={(e) => {
                if (parseInt(e.target.value) && e.target.value.length <= 10) {
                  findPhone(e.target.value.toLowerCase().trim());
                }
              }}
              onChange={(e) => {
                if (e.target.value.length <= 10) {
                  setPhone(e.target.value.trim());
                }
                if (
                  e.target.value.trim().slice(0, 1) == "9" ||
                  e.target.value.trim().slice(0, 1) == "8" ||
                  e.target.value.trim().slice(0, 1) == "7" ||
                  e.target.value.trim().slice(0, 1) == "6"
                ) {
                  setMobileDigit(false);
                } else {
                  setMobileDigit(true);
                }
                if (parseInt(e.target.value) && e.target.value.length <= 10) {
                  findPhone(e.target.value.toLowerCase().trim());
                }
                let input = [
                  {
                    tagid: "phone",
                    text:
                      e.target.value.trim().length > 10
                        ? phone
                        : e.target.value.trim(),
                    regex_name: "any_number",
                    required: true,
                    errmsg: "Enter a valid Phone Number.",
                    allow_negative: false,
                    allow_decimal: false,
                    // other_chars: false,
                    // allow_numbers: true,
                    // min: 10,
                    // max: 10,
                  },
                ];
                realTimeValidation(input, "phone");
              }}
            ></CInput>
            {mobileDigit ? (
              <Err data={{ err: "Invalid mobile number" }}></Err>
            ) : (
              err.map((obj, index) => {
                if (obj.tagid === "phone") {
                  return <Err key={index} data={{ err: obj.errmsg }}></Err>;
                } else {
                  return null;
                }
              })
            )}
            {samePhone && (
              <Err
                data={{ err: "This Phone number is already registered" }}
              ></Err>
            )}

            <CLabel className={"mb-0 mt-2 mb-1"}>Gender</CLabel>
            <CCol className={"d-flex  justify-content-between"}>
              <Form.Check
                name="gender"
                checked={radio == 1 ? true : false}
                onClick={(e) => {
                  setRadio(1);
                  let input = [
                    {
                      tagid: "gender",
                      text: e.target.value === "select" ? "" : e.target.value,
                      regex_name: "free_text",
                      required: true,
                      errmsg: "Please select gender.",
                    },
                  ];
                  realTimeValidation(input, "gender");
                  setGender(e.target.value);
                }}
                type={"radio"}
                label={`Male`}
                value={"Male"}
                className="mr-1"
              />
              <Form.Check
                checked={radio == 2 ? true : false}
                name="gender"
                onClick={(e) => {
                  setRadio(2);
                  let input = [
                    {
                      tagid: "gender",
                      text: e.target.value === "select" ? "" : e.target.value,
                      regex_name: "free_text",
                      required: true,
                      errmsg: "Please select gender.",
                    },
                  ];
                  realTimeValidation(input, "gender");
                  setGender(e.target.value);
                }}
                type={"radio"}
                label={`Female`}
                value={"Female"}
                className="mr-1"
              />
              <Form.Check
                name="gender"
                checked={radio == 3 ? true : false}
                onClick={(e) => {
                  setRadio(3);
                  let input = [
                    {
                      tagid: "gender",
                      text: e.target.value === "select" ? "" : e.target.value,
                      regex_name: "free_text",
                      required: true,
                      errmsg: "Please select gender.",
                    },
                  ];
                  realTimeValidation(input, "gender");
                  setGender(e.target.value);
                }}
                type={"radio"}
                label={`Prefer not to say`}
                value={"Decline to State"}
              />
            </CCol>
            {err.map((obj, index) => {
              if (obj.tagid === "gender") {
                return <Err key={index} data={{ err: obj.errmsg }}></Err>;
              } else {
                return null;
              }
            })}

            <CCol className={"w-100 px-0 mt-4"}>
              <CCol className={"ml-auto col-2 px-0"}>
                <CButton
                  disabled={sameEmail}
                  onClick={() => {
                    pageOne();
                  }}
                  className={`btn ${
                    sameEmail ? "btn-secondary" : "btn-primary"
                  }`}
                >
                  Next
                </CButton>
              </CCol>
            </CCol>
          </CCol>
        )}
        {page == 3 && (
          <CCol
            style={{ boxShadow: "0px 4px 15px #d8d8d8" }}
            className={"mx-auto border py-3 bg-white rounded"}
            xs="12"
            md="12"
            lg="12"
          >
            <p className="h5">Personal Info</p>
            <hr className="mt-1"></hr>
            <CLabel className={"mb-0"}>Date of birth</CLabel>
            {/* <CCol
              className={"p-1"}
              style={{ border: "1px solid #cfc9c8", borderRadius: 4 }}
            > */}
            <DatePicker
              style={{ color: "black" }}
              className="form-control text-dark date"
              placeholderText="Date of Birth"
              peekNextMonth
              
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              scrollableMonthYearDropdown
              scrollableYearDropdown
              maxDate={minimumDate}
              dateFormat="dd-MMM-yyyy"
              selected={date}
              onChange={(date) => {
                setDateErr(false);
                setDOB(date);
              }}
            />
            {/* </CCol> */}
            {dateErr && (
              <Err data={{ err: "Please Enter Date of Birth" }}></Err>
            )}
            <CLabel className={"mb-0 mt-2"}>Select State</CLabel>
            <CSelect
              style={{ color: "black" }}
              onChange={(e) => {
                setStateShow(true);
                onStateSelect(e.target.value);

                let state = statess.find((obj) => {
                  if (e.target.value) {
                    if (obj.gstcode === e.target.value) {
                      return obj.state;
                    } else {
                      return "";
                    }
                  }
                });
                setStates(e.target.value);
                let input = [
                  {
                    tagid: "candidateState",
                    text: state.state,
                    regex_name: "text",
                    required: true,
                    errmsg: "Please select State.",
                  },
                ];
                realTimeValidation(input, "candidateState");
              }}
              custom
              name="ccmonth1"
              id="ccmonth1"
            >
              <option hidden={stateShow} value="">
                Select State{" "}
              </option>
              {statess.map((sr, index) => {
                return (
                  <option
                    key={index}
                    selected={state == sr.gstcode ? true : false}
                    value={sr.gstcode}
                  >
                    {sr.state}
                  </option>
                );
              })}
            </CSelect>
            {err.map((obj, index) => {
              if (obj.tagid === "candidateState") {
                return <Err key={index} data={{ err: obj.errmsg }}></Err>;
              } else {
                return null;
              }
            })}
            <CLabel className={"mb-0 mt-2"}>Select city</CLabel>
            <CSelect
              style={{ color: "black" }}
              selected={city}
              value={city}
              onChange={(e) => {
                setCity(e.target.value);
                setShowCitySelect(true);
                let input = [
                  {
                    tagid: "city",
                    text: e.target.value === "select" ? "" : e.target.value,
                    regex_name: "text",
                    required: true,
                    errmsg: "Please select City.",
                  },
                ];
                realTimeValidation(input, "city");
              }}
              custom
              name="ccmonth"
              id="ccmonth"
            >
              <option hidden={showCitySelect} value="select">
                Select City
              </option>
              {cities.map((sr, index) => {
                return (
                  <option key={index} value={sr}>
                    {sr}
                  </option>
                );
              })}
            </CSelect>
            {err.map((obj, index) => {
              if (obj.tagid === "city") {
                return <Err key={index} data={{ err: obj.errmsg }}></Err>;
              } else {
                return null;
              }
            })}
            <CLabel htmlFor="Exp" className={"mb-0 mt-2"}>
              Marital Status
            </CLabel>
            <CSelect
              style={{ color: "black" }}
              selected="marriedStatus"
              value={marriedStatus}
              onChange={(e) => {
                let input = [
                  {
                    tagid: "marriedStatus",
                    text: e.target.value === "select" ? "" : e.target.value,
                    regex_name: "free_text",
                    required: true,
                    errmsg: "Please select maritial status.",
                  },
                ];
                realTimeValidation(input, "marriedStatus");
                setMarriedStatus(e.target.value);
              }}
              custom
              name="ccmonth"
              id="ccmonth"
            >
              <option value="select">Select Marital Status</option>
              <option value="single">Single </option>
              <option value="married">Married </option>
              <option value="divorced">Divorced </option>
              <option value="widow">Widow </option>
            </CSelect>
            {err.map((obj, index) => {
              if (obj.tagid === "marriedStatus") {
                return <Err key={index} data={{ err: obj.errmsg }}></Err>;
              } else {
                return null;
              }
            })}

            <CCol className={"w-100 px-0 mt-4"}>
              <CCol className={"ml-auto col-5 d-flex px-0"}>
                <CButton
                  onClick={() => {
                    // setGender("");
                    setPagenumber(1);
                  }}
                  className={"btn btn-secondary mr-1 ml-auto "}
                >
                  Prev
                </CButton>
                <CButton
                  disabled={sameEmail}
                  onClick={() => {
                    pageThree();
                  }}
                  className={`btn ${
                    sameEmail ? "btn-secondary" : "btn-primary"
                  }`}
                >
                  Next
                </CButton>
              </CCol>
            </CCol>
          </CCol>
        )}
        {page == 2 && (
          <CCol
            className={"mx-auto border py-3 bg-white rounded"}
            xs="12"
            md="12"
            lg="12"
          >
            <small>2 of 3 Completed</small>
            <p className="h5">Additional info</p>
            <hr className="mt-1"></hr>
            <CRow className={"d-md-flex justify-content-around d-none "}>
              <CCol md="5" xs="11" className={"px-0"}>
                <CLabel className={"mb-0"}>
                  <>Current CTC (LPA in &#8377;)</>
                </CLabel>
                <CInput
                  style={{ color: "black" }}
                  placeholder="For eg: 3"
                  className={"shadow-none"}
                  type=""
                  value={c_ctc}
                  onChange={(e) => {
                    // if (
                    //   parseInt(e.target.value) &&
                    //   parseInt(e.target.value) >= parseInt(e_ctc) &&
                    //   e_ctc
                    // ) {
                    //   setC_ctcErr(true);
                    //   // setRange(true);
                    // } else {
                    //   if (e_ctcErr) {
                    //     setE_ctcErr(false);
                    //   }
                    //   setC_ctcErr(false);
                    //   // setRange(false);
                    // }
                    let input = [
                      {
                        tagid: "c_ctc",
                        text: e.target.value,
                        regex_name: "any_number",
                        required: true,
                        errmsg: "Enter valid CTC.",
                        allow_decimal: true,
                        allow_negative: false,
                      },
                    ];
                    setC_ctc(e.target.value.trim());
                    realTimeValidationTwo(input, "c_ctc");
                  }}
                ></CInput>
                {errTwo.map((obj, index) => {
                  if (obj.tagid === "c_ctc") {
                    return <Err key={index} data={{ err: obj.errmsg }}></Err>;
                  }
                })}
              </CCol>
              <CCol md="5" xs="11" className={"px-0"}>
                <CLabel className={"mb-0"}>
                  <>Expected CTC(LPA in &#8377;)</>
                </CLabel>
                <CInput
                  style={{ color: "black" }}
                  placeholder="For eg: 4"
                  className={"shadow-none"}
                  value={e_ctc}
                  onChange={(e) => {
                    // if (
                    //   parseInt(e.target.value) &&
                    //   parseInt(e.target.value) <= parseInt(c_ctc) &&
                    //   c_ctc
                    // ) {
                    //   setE_ctcErr(true);
                    // } else {
                    //   setE_ctcErr(false);
                    //   if (c_ctcErr) {
                    //     setC_ctcErr(false);
                    //   }
                    // }
                    let input = [
                      {
                        tagid: "e_ctc",
                        text: e.target.value == "0" ? "" : e.target.value,
                        regex_name: "any_number",
                        required: true,
                        errmsg: " Enter valid CTC.",
                        allow_decimal: true,
                        allow_negative: false,
                      },
                    ];
                    setE_ctc(e.target.value.trim());
                    realTimeValidationTwo(input, "e_ctc");
                  }}
                ></CInput>
                {errTwo.map((obj, index) => {
                  if (obj.tagid === "e_ctc") {
                    return <Err key={index} data={{ err: obj.errmsg }}></Err>;
                  }
                })}
              </CCol>
            </CRow>
            <CLabel className={"mb-0 d-md-none"}>
              <>Current CTC(LPA in &#8377;)</>
            </CLabel>
            <CInput
              style={{ color: "black" }}
              placeholder="For eg: 3"
              className={"shadow-none d-md-none"}
              type=""
              value={c_ctc}
              onChange={(e) => {
                // if (
                //   parseInt(e.target.value) &&
                //   parseInt(e.target.value) >= parseInt(e_ctc) &&
                //   e_ctc
                // ) {
                //   setC_ctcErr(true);
                //   // setRange(true);
                // } else {
                //   if (e_ctcErr) {
                //     setE_ctcErr(false);
                //   }
                //   setC_ctcErr(false);
                //   // setRange(false);
                // }
                let input = [
                  {
                    tagid: "c_ctc",
                    text: e.target.value,
                    regex_name: "any_number",
                    required: true,
                    errmsg: "Enter valid CTC.",
                    allow_decimal: true,
                    allow_negative: false,
                  },
                ];
                setC_ctc(e.target.value.trim());
                realTimeValidationTwo(input, "c_ctc");
              }}
            ></CInput>
            <p className="d-md-none">
              {errTwo.map((obj, index) => {
                if (obj.tagid === "c_ctc") {
                  return <Err key={index} data={{ err: obj.errmsg }}></Err>;
                }
              })}
            </p>
            <CLabel className={"mb-0 d-md-none"}>
              {" "}
              <>Expected CTC(LPA in &#8377;)</>
            </CLabel>
            <CInput
              style={{ color: "black" }}
              placeholder="For eg: 4"
              className={"shadow-none d-md-none"}
              value={e_ctc}
              onChange={(e) => {
                // if (
                //   parseInt(e.target.value) &&
                //   parseInt(e.target.value) <= parseInt(c_ctc) &&
                //   c_ctc
                // ) {
                //   setE_ctcErr(true);
                // } else {
                //   setE_ctcErr(false);
                //   if (c_ctcErr) {
                //     setC_ctcErr(false);
                //   }
                // }
                let input = [
                  {
                    tagid: "e_ctc",
                    text: e.target.value == "0" ? "" : e.target.value,
                    regex_name: "any_number",
                    required: true,
                    errmsg: " Enter valid CTC .",
                    allow_decimal: true,
                    allow_negative: false,
                  },
                ];
                setE_ctc(e.target.value.trim());
                realTimeValidationTwo(input, "e_ctc");
              }}
            ></CInput>
            <p className="d-md-none">
              {errTwo.map((obj, index) => {
                if (obj.tagid === "e_ctc") {
                  return <Err key={index} data={{ err: obj.errmsg }}></Err>;
                }
              })}
            </p>
            <CLabel className={"mb-0 mt-2"}>
              How soon would you be looking to start?
            </CLabel>

            <CSelect
              style={{ color: "black" }}
              onChange={(e) => {
                let input = [
                  {
                    tagid: "noticepd",
                    text:
                      e.target.value === "select"
                        ? ""
                        : e.target.value === "7"
                        ? "07"
                        : e.target.value === "0"
                        ? "00"
                        : e.target.value === "90+"
                        ? "90"
                        : e.target.value,
                    regex_name: "free_text",
                    required: true,
                    errmsg: "Please select Notice Period.",
                  },
                ];
                realTimeValidationTwo(input, "noticepd");
                setn_periode(e.target.value);
              }}
              className={"shadow-none "}
            >
              <option selected={true} value="select">
                Select Notice Period
              </option>
              <option selected={n_periode == "0" ? true : false} value="0">
                Immediate
              </option>
              <option selected={n_periode == "7" ? true : false} value="7">
                7 days{" "}
              </option>
              <option selected={n_periode == "15" ? true : false} value="15">
                15 days{" "}
              </option>
              <option selected={n_periode == "30" ? true : false} value="30">
                30 days{" "}
              </option>
              <option selected={n_periode == "45" ? true : false} value="45">
                45 days{" "}
              </option>
              <option selected={n_periode == "60" ? true : false} value="60">
                60 days{" "}
              </option>
              <option selected={n_periode == "90" ? true : false} value="90">
                90 days{" "}
              </option>
              <option selected={n_periode == "90+" ? true : false} value="90+">
                90+ days{" "}
              </option>
            </CSelect>
            {errTwo.map((obj, index) => {
              if (obj.tagid === "noticepd") {
                return <Err key={index} data={{ err: obj.errmsg }}></Err>;
              }
            })}

            <CRow>
            <CCol xs="6">
              <CLabel htmlFor="Exp" className={"mt-2 py-0 mb-0"}>
                  Experience(In years)
                </CLabel>
                  <CInput
                  style={{color:"black"}}
                    id="work_Exp"
                    value={experience}
                    onChange={(e) => {
                     
                      let input = [
                        {
                          tagid: "exp",
                          text: e.target.value.trim(),
                          regex_name: "any_number",
                          required: true,
                          errmsg: "Invalid entry for Experience.",
                          allow_decimal: true,
                          allow_negative: false,
                        },
                      ];
                      setExperience(e.target.value.trim());
                      realTimeValidationTwo(input, "exp");
                    }}
                    placeholder="Enter experience"
                    required
                  />
                  {errTwo.map((obj, index) => {
                    if (obj.tagid === "exp") {
                      return <Err key={index} data={{ err: obj.errmsg }}></Err>;
                    } else {
                      return null;
                    }
                  })}
              </CCol>
              <CCol xs='6'>
                <CLabel htmlFor="Exp" className={"mt-2 py-0 mb-0"}>
                  Source
                </CLabel>
            {/* <CCol className={"m-0 p-0 d-flex "}> */}
                  <CSelect
                    style={{ color: "black" }}
                    value={source}
                    onChange={(e) => {
                      setSource(e.target.value === "select" ? "" : e.target.value);
                      let input = [
                        {
                          tagid: "source",
                          text: e.target.value === "select" ? "" : e.target.value,
                          regex_name: "text",
                          required: true,
                          errmsg: "Please select the Source.",
                        },
                      ];
                      realTimeValidationTwo(input, "source");
                    }}
                    custom
                    name="ccmonth"
                    id="ccmonth"
                  >
                    <option value="select">Select Source </option>

                    <option value={"LinkedIn"}>LinkedIn</option>
                    <option value={"Hirect"}>Hirect</option>
                    <option value={"JobPortals"}>JobPortals</option>
                    <option value={"Vendor"}>Vendor</option>
                    <option value={"Campus"}>Campus</option>
                    <option value={"TrainingInstitute"}>TrainingInstitute</option>
                    <option value={"EmployeeReferal"}>EmployeeReferal</option>
                    <option value={"MobigicHR"}>MobigicHR</option>
                    <option value={"Technovenom"}>Technovenom</option>
                    <option value={"Iquesstbee"}>Iquesstbee</option>
                    <option value={"Quesscorp"}>Quesscorp</option>
                    <option value={"Millionminds"}>Millionminds</option>
                    <option value={"Obrimo"}>Obrimo</option>
                    <option value={"Connexink"}>Connexink</option>
                    <option value={"Zordial"}>Zordial</option>
                    <option value={"Winfomi"}>Winfomi</option>
                    <option value={"Moreyeahs"}>Moreyeahs</option>
                    <option value={"DianApps"}>DianApps</option>
                  </CSelect>
                  {/* </CCol> */}
                  {errTwo.map((obj, index) => {
                    if (obj.tagid === "source") {
                      return <Err key={index} data={{ err: obj.errmsg }}></Err>;
                    } else {
                      return null;
                    }
                  })}
              </CCol>
              
            </CRow>
            
            <CLabel className={"mb-0 mt-2"}> Upload Resume (PDF only)</CLabel>
            <input
              accept="application/pdf,.pdf"
              type="file"
              style={{ display: "none" }}
              s
              onClick={() => {
                setFile([]);
              }}
              onChange={(e) => {
                if (e.target.files[0]) {
                  let text = e.target.files[0].name;

                  let path = text.split(".");
                  if (path[path.length - 1] == "pdf") {
                    if (e.target.files[0]) {
                      if (e.target.files[0].size < 2097152) {
                        setSizeErr(false);
                        setFile(e.target.files);
                        setFileErr(false);
                      } else {
                        setSizeErr(true);
                        setFileErr(false);
                      }
                    } else {
                      setSizeErr(false);
                    }
                  } else {
                    setFileErr(true);
                  }
                }

                // console.log(e.target.files[0]);
              }}
              class="form-control-file"
              id="exampleFormControlFile1"
            ></input>
            <div>
              <label
                for="exampleFormControlFile1"
                className="d-flex align-items-center"
              >
                <div
                  style={{
                    border: "1px solid #848484",
                    borderRadius: "3px",
                    backgroundColor: "#efefef",
                    paddingTop: "2px",
                    paddingBottom: "2px",
                  }}
                  className="px-2 mr-1"
                >
                  Choose File
                </div>

                <div>{file[0] ? file[0].name : "No file chosen"} </div>
              </label>
            </div>
            {/* {size?<Err data={{ err: "Upload file under 2MB" }}></Err>:fileErr ? <Err data={{ err: "Upload your cv" }}></Err>:""} */}
            {size && <Err data={{ err: "Upload file under 2MB" }}></Err>}
            {fileErr && !size && <Err data={{ err: "Upload your CV" }}></Err>}
            {/* <Form.
              id="custom-file-translate-scss"
               label={file[0] ? file[0].name : "Select File"}
              lang="en"
              custom
              onChange={(e) => {
                setFileErr(false);
                setFile(e.target.files);
                // console.log(e.target.files[0]);
              }}
            /> */}

            <hr></hr>
            <CCol className={"w-100 px-0"}>
              <CCol className={"ml-auto col-5 d-flex px-0"}>
                <CButton
                  onClick={() => {
                    // setGender("");
                    setPagenumber(3);
                  }}
                  className={"btn btn-secondary mr-1 ml-auto "}
                >
                  Prev
                </CButton>
                <CButton
                  disabled={clicked}
                  onClick={() => {
                    pageTwoValidation();
                  }}
                  className={"btn btn-primary"}
                >
                  Submit
                </CButton>
              </CCol>
            </CCol>
          </CCol>
        )}
        {/* {page === 3 && (
          <CCol className={"mx-auto text-center "} sm="8">
            <p className="h3 mt-5">Thank you for your time {name}!</p>
            <p className=" mt-4">
              We will contact you shorly at the following email address {email}{" "}
              or phone number {phone}
            </p>

            <hr></hr>
            <CCol className={"w-100 px-0"}>
              <CCol className={"ml-auto col-md-5 d-flex px-0"}>
                <CButton
                  onClick={() => {
                    setPagenumber(2);
                  }}
                  className={"btn btn-secondary mr-1 ml-auto "}
                >
                  Prev
                </CButton>
                <CButton
                  onClick={() => {
                    submit();
                  }}
                  className={"btn btn-primary"}
                >
                  Submit
                </CButton>
              </CCol>
            </CCol>
          </CCol>
        )} */}
      </CCol>
    </CRow>
  );
};
let style = {
  color: "red",
  width: "100%",
  display: "block",
};
function Err(props) {
  const [err, setErr] = useState(props.data.err);
  return <small style={style}>{err}</small>;
}
